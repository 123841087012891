import React from 'react';
import { Grid, IconButton } from "@mui/material";
import ArrowBack from '@mui/icons-material/ArrowBack';

const PricingPage = () => {

  // Constants for Stripe Pricing Table and Publishable Key
  const PRICING_TABLE_ID = process.env.REACT_APP_PRICING_TABLE_ID;
  const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY;

  return (
    <div style={{ backgroundColor: '#fffcf8', textAlign: 'center', padding: '2em' }}>
      <IconButton component="a" href="/">
        <ArrowBack />
      </IconButton>
      <img
       src={require("../../../assets/images/logo-icon.png")}// Ensure the image is in this path
        width="200px"
        height="200px"
        alt="logo"
      />
      <stripe-pricing-table
        pricing-table-id={PRICING_TABLE_ID}
        publishable-key={publishableKey}
      >
      </stripe-pricing-table>
    </div>
  );
}

export default PricingPage;
