// TODO: handle Alerts and redux
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Alert,
  Grid,
} from "@mui/material";
import { useUserState } from "@reduxHooks/useLoginState";
import axios from "axios"; // for fetching quotes
import { useParseSDK } from "hooks/useParseSDK";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { brandColors } from "../../brandColors";
import { handleSubscribe } from "hooks/handleSubscription";
const { Parse } = useParseSDK();

const LoginScreen = () => {
  const [dynamicQuote, setDynamicQuote] = useState<any>("");
  const [loginError, setloginError] = useState<string>("");
  const [isLoaing, setIsLoading] = useState(false);
  //HINT: NEW WAY TO USE PARSESDK

  const navigate = useNavigate();
  const { setLoginState } = useUserState();

  const fetchDynamicQuote = async () => {
    try {
      const response = await axios.get(
        "https://api.quotable.io/random?tags=famous-quotes"
      );
      setDynamicQuote(response.data);
    } catch (error) {
      console.error(error);
      setDynamicQuote({ content: "Opps... Something went wrong" });
    }
  };

  // RHF validation schema
  const validationSchema = yup
    .object({
      email: yup.string().email("Invalid Email").required("Email is Required"),
      password: yup.string().required("Password is Required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = async (data: any) => {
    const usernameValue = data?.email;
    const passwordValue = data?.password;
    try {
      setIsLoading(true);
      const loggedInUser = await Parse.User.logIn(usernameValue, passwordValue);
      console.log(loggedInUser);
      const userData = []; // Array to store user data

      // Retrieve the user's role
      const userRoleQuery = new Parse.Query(Parse.Role);
      userRoleQuery.equalTo("users", loggedInUser);
      const userRole = await userRoleQuery.first();
      const roleName = userRole ? userRole.get("name") : "No Role Assigned";

      const userId = loggedInUser.id;
      // Retrieve the user's role in the Business object
      const businessQuery = new Parse.Query("Business");
      businessQuery.equalTo("Business_Users", userId);
      const business = await businessQuery.first();

      const businessObjectId = business ? business.id : "No Business Found";
      const businessName = business
        ? business.get("Business_Name")
        : "Unknown Business";
      const businessNumber = business
        ? business.get("Business_Number")
        : "Unknown Business";
      const businessOwner = business
        ? business.get("Business_Owner")
        : "Unknown Business";
      const ownerUserQuery = new Parse.Query("_User");
      ownerUserQuery.equalTo("objectId", businessOwner.id);
      const ownerUser = await ownerUserQuery.first();
      const ownerEmail = ownerUser.get("email");
      const ownerUsername = ownerUser.get("username");

      const businessAddress = business
        ? business.get("Business_Address")
        : "Unknown Business";
      const businessUsers = business
        ? business.get("Business_Users")
        : "Unknown Business";
      const businessABN = business
        ? business.get("Business_ABN")
        : "Unknown Business";

      const username = loggedInUser.get("username");
      const useremail = loggedInUser.get("email");
      const isAuto = loggedInUser.get("isAuto");
      userData.push({
        role: roleName,
        business: {
          id: businessObjectId,
          name: businessName,
          phone: businessNumber,
          owner: {
            id: businessOwner.id,
            username: ownerUsername,
            email: ownerEmail,
          },
          businessHours: business.get("businessHours")
            ? business.get("businessHours")
            : [],
          address: businessAddress,
          users: businessUsers,
          abn: businessABN,
          paymentCycle: business.get("payment_cycle_frequency")
            ? business.get("payment_cycle_frequency")
            : "weekly",
          paymentCycleStartDate: business.get("payment_cycle_start")
            ? business.get("payment_cycle_start").toISOString()
            : new Date().toISOString(),
          accountantName: business.get("Accountant_Name")
            ? business.get("Accountant_Name")
            : "",
          accountantEmail: business.get("Accountant_Email")
            ? business.get("Accountant_Email")
            : "",
        },
        user: {
          id: userId,
          firstName: loggedInUser.get("First_Name"),
          lastName: loggedInUser.get("Last_Name"),
          username: username,
          email: useremail,
          isAuto: isAuto,
        },
      });

      setLoginState({ user: userData || [] });

      navigate("/dashboard");

      setIsLoading(false);
      await handleSubscribe(userData, fbq, Parse);
      return userData; // Return the user data array
    } catch (error: any) {
      setloginError(error.message);
      setTimeout(() => {
        setloginError("");
      }, 5000);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDynamicQuote();
  }, []);

  return (
    <Grid container spacing={2} minHeight={"100vh"}>
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: brandColors.gray,
        }}>
        {/* Left Container Content */}
        <Box style={{ textAlign: "center" }}>
          <img
            src={require("assets/images/logo-icon.png")}
            alt="logo"
            style={{ width: 300 }}
          />
          <Typography variant="h3" sx={{ color: brandColors.white }}>
            OMAR
          </Typography>
          <Typography
            sx={{
              color: brandColors.white,
              maxWidth: "300px",
              marginBottom: "16px",
            }}>
            {dynamicQuote.content}
          </Typography>
          <Typography sx={{ color: brandColors.white, fontStyle: "italic" }}>
            {dynamicQuote.author}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: brandColors.white,
        }}>
        {/* Right Container Form */}
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: { xs: "100%", md: "50%" }, // 100% width on xs screens, 50% width from md screens and up
            px: 3, // padding on the x-axis
            margin: "0 auto", // centers the form in the parent container
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {Boolean(loginError) && (
            <Alert
              severity="error"
              sx={{ marginBottom: "32px", width: "100%" }}>
              {loginError}
            </Alert>
          )}
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            size="small"
            {...register("email")}
            sx={{ marginBottom: "15px" }}
            error={Boolean(errors.email)}
            helperText={errors?.email?.message}
          />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            type="password"
            size="small"
            {...register("password")}
            sx={{ marginBottom: "10px" }}
            error={Boolean(errors.password)}
            helperText={errors?.password?.message}
          />
          <Button
            variant="outlined"
            sx={{ width: "100%", marginBottom: "10px" }}
            type="submit">
            {!isLoaing ? "Login" : <CircularProgress size={25} />}
          </Button>
          <Button
            onClick={() => navigate("/reset-password")}
            sx={{ width: "100%", marginBottom: "10px" }}>
            Forgot your password?
          </Button>
          <Button
            onClick={() => navigate("/pricing-table")}
            sx={{ width: "100%" }}>
            Signup
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

// const styles = {
//   container: {
//     display: "flex",
//     height: "100vh",
//     width: "100%",
//     backgroundColor: "green",
//   },
//   leftContainer: {
//     backgroundColor: brandColors.gray,
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     flexGrow: 1,
//   },
//   rightContainer: {
//     backgroundColor: brandColors.white,
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     flexGrow: 1,
//   },
//   logoContainer: {
//     alignItems: "center",
//   },
//   logo: {
//     width: 300,
//   },
//   title: {
//     fontSize: 48,
//     color: brandColors.white,
//     textAlign: "center",
//     alignItems: "center",
//   },
//   dynamicQuote: {
//     fontSize: 16,
//     color: brandColors.white,
//     textAlign: "center",
//     maxWidth: "300px",
//     marginBottom: "16px",
//   },
//   quoteAuthor: {
//     fontSize: 14,
//     color: brandColors.white,
//     fontStyle: "italic",
//   },
//   input: {
//     width: "40%",

//     borderRadius: 10,
//     paddingLeft: 10,
//   },
//   button: {
//     height: 50,
//     backgroundColor: brandColors.primary,
//     borderRadius: 10,
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   buttonText: {
//     fontSize: 20,
//     color: brandColors.white,
//   },
//   resetButton: {
//     alignItems: "center",
//     justifyContent: "center",
//     marginTop: "25px",
//   },
//   resetButtonText: {
//     fontSize: 16,
//     color: brandColors.gray,
//     textDecorationLine: "underline",
//   },
// };

export default LoginScreen;
