import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useParseSDK } from "hooks/useParseSDK";
import Lottie from "lottie-react";
import loadingAnimation from "assets/lotties/OMAR_AI_LOOK.json";

export const ProtectedRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { Parse } = useParseSDK();

  useEffect(() => {
    const verifyUser = async () => {
      if (!navigator.onLine) {
        console.warn("You are offline. Using local state for authentication.");
        const currentUser = Parse.User.current();
        setIsAuthenticated(!!currentUser);
        setIsLoading(false);
        return;
      }

      const currentUser = Parse.User.current();
      if (!currentUser) {
        setIsAuthenticated(false);
        setIsLoading(false);
        return;
      }

      try {
        // First, check the user's role
        const userRoleQuery = new Parse.Query(Parse.Role);
        userRoleQuery.equalTo("users", currentUser);
        const userRole = await userRoleQuery.first();
        const roleName = userRole ? userRole.get("name") : null;

        // If the user is not a Manager, deny access
        if (roleName !== "Manager") {
          setIsAuthenticated(false);
          setIsLoading(false);
          return;
        }

        // Check if the email is "jjspizza1@hotmail.com" and skip Stripe verification
        const userEmail = currentUser.get("email");
        if (userEmail === "jjspizza1@hotmail.com") {
          setIsAuthenticated(true); // Skip Stripe check for this email
          setIsLoading(false);
          return;
        }

        // If the email is not "jjspizza1@hotmail.com", continue with Stripe verification
        const stripeCustomerId = currentUser.get("customerId");

        if (stripeCustomerId) {
          const result = await Parse.Cloud.run(
            "verifyStripeSubscriptionStatus",
            { stripeCustomerId: stripeCustomerId }
          );
          setIsAuthenticated(result.hasValidSubscription); // Check if the subscription is valid
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Error verifying user:", error);
        setIsAuthenticated(false);
      }

      setIsLoading(false);
    };

    verifyUser();
  }, [Parse]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        <Lottie
          animationData={loadingAnimation}
          loop
          style={{ width: 200, height: 200 }}
        />
      </div>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};
