export const handleSubscribe = async (loginState, fbq, Parse) => {
  const eventTime = Math.floor(Date.now() / 1000);

  // Hash the email using SHA-256
  const hashedEmail = crypto
    .SHA256(loginState.user.email)
    .toString(crypto.enc.Hex);

  // Trigger Meta Pixel event
  // fbq("track", "Subscribed", {
  //   value: 63,
  //   currency: "AUD",
  //   eventID: "unique_event_id", // Generate a unique event ID
  // });

  // Call Back4App cloud function to send CAPI event
  try {
    const result = await Parse.Cloud.run("sendEventToMeta", {
      eventName: "Subscribed",
      eventTime,
      actionSource: "website",
      email: [hashedEmail],
      phone: loginState.business.phone ? [loginState.business.phone] : [null],
      fbc: null,
      clientIpAddress: null,
      clientUserAgent: null,
      currency: "AUD",
      value: 63,
    });
    console.log("Event sent to Meta via CAPI:", result);
  } catch (error) {
    console.error("Error sending event via CAPI:", error);
  }
};
