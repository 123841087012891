/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MiniDrawer } from "@components/SideMenu";
import LoginScreen from "@screens/UserScreens/LoginScreen";
import SignUp from "./screens/UserScreens/SignUpScreen";
import { ProtectedRoutes } from "./routes/ProtectedRoutes";
import StockScreen from "./screens/Inventory Management/StockNew";
import ScheduledRecipesList from "./screens/ProductionList/MainComponent";
import MainComponent from "@screens/Timesheet/MainComponent";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "redux/store"; // Adjust the path as necessary
import RecipeList from "screens/ProductList/RecipeDB";
import ExpensesDashboard from "screens/ExpensesDashboard/ExpensesDashboard";
import RecipeCostDisplay from "screens/CostWizard/RecipeCostDisplay";
import ProfileScreen from "screens/ProfileScreen/ProfileScreen";
import UserResetPassword from "@screens/UserScreens/PRScreen";
import SupplierInfo from "screens/SupplierInfo";
import SupplierProfile from "screens/SupplierInfo/RenderSuppliers";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import MainChatComponent from "@screens/OMAR_AI/MainComponent";
import MobileOnlyView from "@screens/MobileView";
import PricingPage from "@components/Signup/HOC/PricingWrapper";
import LandingPage from "@screens/LandingPage/LandingPage";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ID,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: "testenv",
  // Other optional properties like server info, code version, and person details
};

// Import other screens/componentscreenss as necessary

function App() {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // 768px is a common breakpoint for mobile devices
    };

    // Check on mount and updates
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobile) {
    return <MobileOnlyView />;
  }

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <Routes>
                {/* Public Routes */}
                {/* <Route path="/" element={<LandingPage />} /> */}
                <Route path="/" element={<LoginScreen />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/pricing-table" element={<PricingPage />} />
                <Route path="/reset-password" element={<UserResetPassword />} />

                {/* Protected Routes */}
                <Route element={<ProtectedRoutes />}>
                  <Route path="/dashboard" element={<MiniDrawer />}>
                    <Route index element={<RecipeList />} />
                    <Route path="/dashboard/stock" element={<StockScreen />} />
                    <Route
                      path="/dashboard/timesheet-wizard"
                      element={<MainComponent />}
                    />
                    <Route
                      path="/dashboard/expenses"
                      element={<ExpensesDashboard />}
                    />
                    <Route
                      path="/dashboard/cost-wizard"
                      element={<RecipeCostDisplay />}
                    />
                    <Route
                      path="/dashboard/production-list"
                      element={<ScheduledRecipesList />}
                    />
                    <Route
                      path="/dashboard/profile"
                      element={<ProfileScreen />}
                    />
                    <Route
                      path="/dashboard/suppliers"
                      element={<SupplierInfo />}
                    />
                    <Route
                      path="/dashboard/supplier/:supplierId"
                      element={<SupplierProfile />}
                    />
                    <Route
                      path="/dashboard/omar-ai"
                      element={<MainChatComponent />}
                    />
                  </Route>
                </Route>
              </Routes>
            </Router>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  );
}

export default App;
